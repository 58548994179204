import * as React from "react";
import { Link } from "gatsby";

// markup
const NotFoundPage = () => {
  return (
    <main className="grid place-items-center">
      <div className="flex flex-col gap-4">
        <div className="flex ">
          <h1 className="text-2xl">Stran ni najdena </h1> <p>404</p>
        </div>
        <p>
          Stran, ki jo želite obiskati, žal ne obstaja.{" "}
          <span role="img" aria-label="Pensive emoji">
            😔
          </span>
        </p>
        <Link className="p-4 text-center bg-yellow-500 rounded-md" to="/">
          Pojdite na naslovno stran
        </Link>
      </div>
      .
    </main>
  );
};

export default NotFoundPage;
